import React, {Component} from 'react';


class Result extends Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <div className="result" key={this.props.length}>
        <h3> {this.props.length} letter words</h3>
        <div className='list'>
          {
            this.props.data.map((item,key) => (
              <div className='listitem' key={key}>
                <a href={"https://en.wiktionary.org/wiki/"+item+"#English"} target="_blank" rel="noopener noreferrer"> {item}</a>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

export default Result;
