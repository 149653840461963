import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Navigation from './navigation.js'

class About extends Component{

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div className="container">
        <Helmet>
          <title>About - Anagram Finder</title>
          <meta name="description" content="Find out more about Anagram Finder here."/>
        </Helmet>
        <div className="header">
          <Navigation/> 
          <div className="hero small">
            <h1>About Anagram Finder</h1>
          </div>
        </div>
        <div className="content links">
            <p>An <a href="https://en.wikipedia.org/wiki/Anagram">anagram</a> is a word or phrase formed by rearranging the letters of a different word or phrase, typically using all the original letters exactly once.</p>
            <p>The Anagram Solver tool uses algorithms to find the anagrams hidden in any word or phrase for you, so you can settle the debate of whether you are actually cheating at Scrabble! </p>
        </div>
      </div>
    );
  }
}

export default withRouter(About);
