import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import request from 'request';
import Result from './result.js'
import Navigation from './navigation.js'

class Solver extends Component{

  constructor(props) {
    super(props)
    this.state = {
      input:'',
      submitted_input:'',
      falseInput:false,
      loading: false,
      data: null
    }
  }

  handleChange = event => {
    this.setState({input: event.target.value, falseInput:false})
  }

  handleSubmit = event => {
    if (this.state.input.length === 0) {
      this.setState({falseInput: true})
    }
    else{
      this.setState({loading:true},(state)=>{
        request.get({
          url:'https://ewwdeaw8i8.execute-api.eu-west-2.amazonaws.com/beta/anagram?input='+this.state.input,
          headers: {
            'x-api-key': 'fpuEYeSlOn7xZUjp6k3gE9GN66LbUgvdegGik545',
            'Content-Type': 'application/json'
          }
        }, (err, response, body)=>{
            if (!err && response.statusCode === 200) {
              const data = JSON.parse(body);
              let size = 0
              Object.keys(data).map((key,index)=>{
                size += data[key].length
              })

              this.setState({data: data, size: size, submitted_input: this.state.input, loading:false}, ()=>{
                var anchor = document.getElementById('result_anchor')
                anchor.scrollIntoView({behavior: 'smooth'});
              })
            }
          }
        )
      })
    }
    event.preventDefault()
  }

  render() {
    return (
      <div className='container'>
          <Helmet>
            <title>Anagram Solver - Find all words in any Anagram - Anagram Finder</title>
            <meta name="description" content="Use the Anagram Solver to find all the English words present in any anagram. Use the Anagram Solver to solve your anagrams, or build your own!"/>
          </Helmet>
          <div className="header">
            <Navigation/>
            <div className="hero">
              <div className="search">
                <h1>Find all possible words in your anagram - easy and fast!</h1>
                <p>Enter up to 30 letters</p>
                <div className="submit_form">
                  <form onSubmit={this.handleSubmit}>
                    <input
                      name='input'
                      value={this.state.input}
                      onChange={this.handleChange}
                      width={9}
                      maxLength={30}
                      />
                    <button id='solve_button'>Find</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <a id="result_anchor"/>
            <div className="results_header">
              {
                this.state.data !== null && <h2>{this.state.size} words found using the letters <span className="bg_highlight">{this.state.submitted_input}</span></h2>
              }
              <div className="bmc-button">
                  <a href="https://www.buymeacoffee.com/anagramfinder" target='_blank' rel="noopener noreferrer">
                    <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=anagramfinder&button_colour=FF5F5F&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" alt='Buy me coffee'/>
                  </a>
                </div>
            </div>
            <div className="results">
              {
                this.state.data !== null &&
                  Object.keys(this.state.data).map((key,index)=>(
                    <Result key={index} length={key} data={this.state.data[key]} />
                  ))
              }
            </div>
            <div className="description">
              <h2>Find hidden words with the Anagram Finder</h2>
                <p>Use the Anagram Finder tool above to solve your anagram - by finding every anagram possible made by unscrambling your letters in the word entered. </p>
                <p>Enter up to 30 letters (including spaces!) and click find - and we'll give you back all the words that be made from those letters. </p>
                <p>For example - if you enter the letters "anagram", the anagram finder will return 50 words that can be spelled using the letters "anagram", such as the 5 letter word "manga", or the 4 letter words "gram" and "rang". </p>
                <p>The Anagram Solver is perfect for finding words you can make in games like <b>Scrabble</b> or <b>Words with Friends</b> - and many other games. By entering your current tiles, the Anagram Finder can help you out if you're stuck. Try it now!</p>
            </div>
            <div className="description">
              <h2>What are Anagrams?</h2>
              <p>
                An anagram is a word, phrase or name formed by rearranging the letters of another. For example, an anagram of <i>spar</i> is <i>rasp.</i>
                <br></br>
                <br></br>
                Anagrams are commonly found in puzzles and games, and can often stump you. If you ever get stuck or need a hint, use Anagram Finder to find the hidden words and solve your problem.
                In particular, if you are playing popular games like <b>Scrabble</b> or <b>Words with Friends</b>, the Anagram Finder can really help you out in a pinch. When you need to rearrange the letters that you have, or if you are stuck or feel like cheating -
                the Anagram Finder can help you solve your anagrams.
                <br></br>
                <br></br>
                
              </p>
            </div>
          </div>
      </div>
    );
  }
}

export default withRouter(Solver);
