import React from "react";
import { Route, Switch } from "react-router-dom";

import Solver from "./components/solver"
import Links from "./components/links"
import About from "./components/about"



export default () =>
  <Switch>
    <Route path='/' exact component={Solver}/>
    <Route path='/links' exact component={Links}/>
    <Route path='/about' exact component={About}/>

  </Switch>;
