import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Navigation from './navigation.js'

class Links extends Component{

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div className="container">
        <Helmet>
          <title>Links - Anagram Finder</title>
          <meta name="description" content="Links to other useful anagram related sites."/>
        </Helmet>
        <div className="header">
          <Navigation/>
          <div className="hero small">
            <h1>Links</h1>
          </div>
        </div>
        <div className="content links">
            <p><a href="https://en.wikipedia.org/wiki/Anagram">Anagram</a> - definition of an anagram, as defined by Wikipedia.</p>
            <p><a href="https://www.anagrams.net/">Anagrams.net</a> - an awesome website that houses a database of anagrams, that can be browsed by subject or keyword.</p>
            <p><a href="https://www.thewordfinder.com/anagram-solver/">thewordfinder.com</a> - a great anagram solver, where you can use wildcards to find anagrams with blank letters.</p>
            <p><a href="https://www.dailywritingtips.com/what-is-an-anagram/">History of Anagrams</a> - an article from <a href="https://www.dailywritingtips.com/">Daily Writing Tips</a> detailing the details of what anagrams are, and their history.</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Links);
