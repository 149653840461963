import React, {Component} from 'react';


class Navigation extends Component {
  constructor(props){
    super(props)
    this.state = {
      reveal: false,
      animation: "",
      responsive: ""
    }
  }

  navToggle = () => {
    this.setState(prevState => (
      {reveal: !prevState.reveal}
    ), () => {
      this.setState({
        animation:  this.state.reveal ? "revealNavResponsive 0.5s forwards" : "hideNavResponsive 0.5s forwards",
        responsive: this.state.reveal ? "responsive" : ""
      },()=>{
        console.log(this.state)
      })
    })
  }


  render() {
    return (
      <div className="navigation">
        <div className="home">
          <a href="/">_anagramfinder</a>
        </div>
        <div className={"links " + this.state.responsive} id='topnav'>
          {/* <a href="/" style={{animation : this.state.animation}}>solver</a> */}
          <a href="/links" style={{animation : this.state.animation}}>links</a>
          <a href="/about" style={{animation : this.state.animation}}>about</a>
          <a className="icon" onClick={this.navToggle}>
              <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>
    )
  }
}

export default Navigation;
